<template>
    <v-container fluid>
        <v-row no-gutters class="d-block">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card>
                    <v-card-title>
                        <v-row>

                            <v-col cols="6" sm="4" md="4" lg="2" xl="2">
                                <v-btn color="success" v-if="permisos[8]" small outlined @click="importacionNuevo()">
                                    <v-icon>upload</v-icon>
                                    Importar
                                </v-btn>
                            </v-col>

                            <v-col cols="6" sm="4" md="4" lg="2" xl="2">

                                <v-btn color="info" outlined small @click="importacionesGet()">
                                    <v-icon>refresh</v-icon>
                                    Refrescar
                                </v-btn>

                            </v-col>

                            <v-col cols="6" sm="4" md="4" lg="2" xl="2" class="pt-0">
                                <v-select
                                    hide-details
                                    v-model="filtros.unidad_operativa_id"
                                    :items="unidadesOperativas"
                                    item-text="unidad_operativa"
                                    item-value="unidad_operativa_id"
                                    @input="importacionesBuscar()"
                                />
                            </v-col>

                            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0">
                                <v-text-field
                                    v-model="search"
                                    hide-details
                                    label="Buscar por Número, Archivo y pulse en el botón."
                                    clearable>
                                    <template v-slot:append>
                                        <v-btn
                                            color="secondary" small outlined
                                            @click="importacionesBuscar()">
                                            <v-icon>search</v-icon>
                                        </v-btn>
                                    </template>
                                    >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            dense
                            class="elevation-3"
                            :headers="tableHeaders"
                            :items="importaciones"
                            :loading="loading"
                            item-key="importacion_id"
                            fixed-header
                            height="65vh"
                            :hide-default-footer="true"
                            disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.numero }}</td>
                                    <td class="text-left">{{ item.archivo_nombre }}</td>
                                    <td class="text-left">{{ item.nombres_apellidos }}</td>
                                    <td class="text-left">{{ item.fecha_crea | fechaESPHora }}</td>
                                    <td class="text-left" v-if="item.procesado">{{ item.fecha_actualiza | fechaESPHora }}</td>
                                    <td class="text-left" v-if="!item.procesado">
                                        <v-chip small color="warning" outlined>
                                            Sin Procesar
                                        </v-chip>
                                    </td>

                                    <td class="text-right">

                                        <v-btn  v-if="!item.procesado" color="success" small outlined @click="procesarData(item)"
                                                v-show="permisos[8]" class="mr-2">
                                            <v-icon>add_task</v-icon>
                                            A Procesar
                                        </v-btn>

                                        <v-btn v-if="item.procesado" color="info" small outlined @click="importacionVer(item)" class="mr-2">
                                            <v-icon>visibility</v-icon>
                                            Ver
                                        </v-btn>
                                        <v-btn color="secondary" small outlined @click="download(item)">
                                            <v-icon>download</v-icon>
                                            Descargar
                                        </v-btn>

                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <v-alert dense type="warning" outlined class="text-center mt-3">
                                    No existen registros.
                                </v-alert>
                            </template>

                        </v-data-table>
                        <Pagination :pagination="pagination" @setCambiarPagina="setCambiarPagina"
                                    v-if="pagination.total"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="showModalProcesar"
            persistent
            fullscreen>
            <v-card>
                <v-card-title class="text-h5 pt-10">
                    Procesando los datos de la Importación, no cierre la ventana. Esto puede durar varios minutos,
                    espere a que termine el
                    proceso
                </v-card-title>

                <v-card-text>
                    <v-progress-linear
                        indeterminate
                        color="info"
                    ></v-progress-linear>
                </v-card-text>

            </v-card>

        </v-dialog>

    </v-container>
</template>

<script>
import Auth from "@/services/auth";
const Pagination = () => import("@/pages/Components/Pagination.vue");

export default {
    name: "AdministracionImportacionesLista",
    components: {Pagination},
    data() {
        return {
            permisos: [],
            importaciones: [],
            unidadesOperativas: [],
            search: "",
            pagination: {},
            pageActual: 1,
            rowsPage: this.$store.state.arrayRowsPages[0],
            loading: false,
            showModalProcesar: false,
            tableHeaders: [
                {
                    text: 'Número',
                    value: 'numero',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Archivo',
                    value: 'archivo_nombre',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Importado por',
                    value: 'nombres_apellidos',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Fecha importación',
                    value: 'fecha_crea',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Fecha procesado',
                    value: 'fecha_actualiza',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Acciones',
                    align: 'center',
                    sortable: false,
                },
            ],
            filtros: {
                unidad_operativa_id: null,
            }
        }
    },

    methods: {
        importacionNuevo() {
            this.$router.push({name: 'AdministracionImportacionesNuevo'});
        },
        importacionesGetFiltros() {

            window.axios.get('/importaciones/get_filtros', {}).then(async response => {
                if (response.data.success) {

                    this.unidadesOperativas = response.data.data.unidadesOperativas;

                    // Despues de asignar los filtros, vamos a traer los datos
                    await this.importacionesGet();

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                })
        },
        importacionesBuscar() {
            this.pageActual = 1;
            this.importacionesGet();
        },
        importacionesGet() {
            this.loading = true;

            let params = {filtros: this.filtros, busqueda: this.search, page: this.pageActual, rowsPage: this.rowsPage};

            window.axios.get('/importaciones', {params}).then(response => {
                if (response.data.success) {

                    let data = response.data.data;
                    this.importaciones = data.importaciones.data;
                    this.pagination = data.pagination;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        download(importacion) {
            //url de descarga del back
            //let url_archivo = `${this.baseURLStorage}/${importacion.archivo_path}`
            //window.open(url_archivo);
            let importacion_id = importacion.importacion_id;
            let params = {importacion_id: importacion_id};
            //window.open(e);
            //console.log(`window.axios.get('${this.baseURLApi}/importaciones/descargar?importacion_id=${importacion_id}')`);
            //window.axios.get(`${this.baseURLApi}/importaciones/descargar?importacion_id=${importacion_id}`)
            let url = `${this.baseURLApi}/importaciones/descargar`;
            console.log('url:' + url);
            console.log('params:' + params);
            window.axios.get(url, {params})
            .then(
                response => {
                    if (response.data.success) {
                        //let filepath = "http://localhost/storage/importacion-102-20240326_012130.xlsx";
                        //let filepath = `${this.baseURLStorage}/${response.data.filepath}`;
                        let filepath = `${this.baseURL}/${response.data.filepath}`;
                        console.log(JSON.stringify(response));
                        console.log(filepath);
                        window.open(filepath);
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                }
            )
            .catch(
                error => {
                    console.log(error)
                    this.$toast.error("No se pudo descargar.");
                }
            )
            .finally(
                () => {
                this.loading = false;
                }
            );
        },
        importacionVer(importacion) {
            if (typeof importacion == "object") {
                let token = window.btoa(importacion.importacion_id);
                this.$router.push({name: 'AdministracionImportacionesVer', query: {token}});
            } else {
                this.$toast.error("Parametro no valido.");
            }
        },
        procesarData(data) {
            this.showModalProcesar = true;

            window.axios.post('/importaciones/procesar', data).then(response => {
                if (response.data.success) {

                    this.$toast.success(response.data.message);

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo Procesar los datos.");
                })
                .finally(() => {
                    this.importacionesGet();
                    this.showModalProcesar = false;
                })
        },
        setCambiarPagina(data) {
            this.pageActual = data.page;
            this.rowsPage = data.rowsPage;
            this.importacionesGet();
        },
    },
    created() {
        Auth.checkPermisos(this.$route.path).then((res) => {
            this.permisos = res;
            this.importacionesGetFiltros();
        });

    }
}
</script>

<style>

</style>
